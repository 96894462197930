import { useState } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands , solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import '../custom.css';
import 'bootstrap/dist/css/bootstrap.css';

export default function Main() {
    const [termsConditionsShow, setTermsConditionsShow] = useState(false);
    const [privacyPolicyShow, setPrivacyPolicyShow] = useState(false);
    const [cookiePolicyShow, setCookiePolicyShow] = useState(false);

    return (
        <>
            <Navbar variant='dark' expand={'lg'} className="navbar-custom" collapseOnSelect>
                <Container fluid>
                    <Navbar.Brand href="#"><img src='/logo.svg' alt='' height='45' className='d-inline-block align-top mx-3'/></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                    <Navbar.Collapse className='my-3' id="offcanvasNavbar">
                        <Nav>
                            <Nav.Link eventKey="1" href='#features'>Features</Nav.Link>
                            <Nav.Link eventKey="2" href='#pricing'>Pricing</Nav.Link>
                            <Nav.Link eventKey="3" href='#support'>Support</Nav.Link>
                            <Nav.Link eventKey="4" href='mailto:hello@cloud-entry.com'>Contact</Nav.Link>
                        </Nav>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Button id='offcanvas-signup-btn' className='mx-2' variant='light' href='/signup'>Try for free</Button>
                            <Button id='offcanvas-login-btn' variant='outline-light' href='/login'>Login</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Row className='main-row'>
                <Col lg={6}>
                    <div className='overview-div'>
                        <h2><strong>The automated financial data entry tool for accountants, bookkeepers and business owners</strong></h2><br/>
                            <Container className='mt-5'>
                                <Row>
                                    <Col lg={2} style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={solid('upload')} size={'4x'}/>
                                    </Col>
                                    <Col>
                                        <strong>Step 1:</strong><br/>Scan and upload costs and sales invoices (or send via email)
                                    </Col>
                                </Row>
                                <Row><br/><br/></Row>
                                <Row>
                                    <Col lg={2} style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={solid('microchip')} size={'4x'}/>
                                    </Col>
                                    <Col>
                                        <strong>Step 2:</strong><br/>Our AI automatically extracts all key accounting information from receipts and invoices and stores it securely in the cloud
                                    </Col>
                                </Row>
                                <Row><br/><br/></Row>
                                <Row>
                                    <Col lg={2} style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={solid('chart-line')} size={'4x'}/>
                                    </Col>
                                    <Col>
                                        <strong>Step 3:</strong><br/>Use our Data Insights tool for detailed analysis of the business income and expenditure
                                    </Col>
                                </Row>
                                <Row><br/><br/></Row>
                                <Row>
                                    <Col lg={2} style={{textAlign:'center'}}>
                                        <FontAwesomeIcon icon={solid('table')} size={'4x'}/>
                                    </Col>
                                    <Col>
                                        <strong>Or:</strong><br/>Export the Expenses report or the CSV data for Excel or third party accounting software
                                    </Col>
                                </Row>
                            </Container>
                        <br/><br/><br/><br/><br/><br/>
                    </div>
                </Col>
                <Col lg={6} sm={false} xs={false} className='overview-cloud-col'></Col>
            </Row>
            <Row className='features'>
                <Col>
                    <h1 id='features' className='mt-5'>Features</h1><br/><br/>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} xs={6}><h5>AI data extraction from receipts and invoices*</h5></Col>
                        <Col lg={4} xs={6}><h5>Secure cloud storage</h5></Col>
                        <Col lg={2}></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} xs={6}><h5>Export data in Excel or CSV format</h5></Col>
                        <Col lg={4} xs={6}><h5>Data Insights tool</h5></Col>
                        <Col lg={2}></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} xs={6}><h5>Invoice upload via email</h5></Col>
                        <Col lg={4} xs={6}><h5><sup>COMING SOON</sup><br/>Android and iOS mobile apps</h5></Col>
                        <Col lg={2}></Col>
                    </Row>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} xs={6}><h5><sup>COMING SOON</sup><br/>Third party software integration</h5></Col>
                        <Col lg={4} xs={6}></Col>
                        <Col lg={2}></Col>
                    </Row>
                    <div><br/><br/><br/>*Supporting PNG, JPEG and PDF file formats<br/><br/><br/></div>
                </Col>
            </Row>
            <Row className='pricing'>
                <h1 id='pricing' className='mb-5 mt-5'>Pricing</h1>
                <Col lg={3} sm={12} className='mb-2'>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title>Single Business</Card.Title>
                            <Card.Text>
                                Manage the bookkeeping for your practice.
                                <br/><br/><strong>£18 /mo</strong>
                            </Card.Text>
                            <Button href='/signup' variant="primary">Try for free</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={12} className='mb-2'>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title>10 Clients</Card.Title>
                            <Card.Text>
                                Manage the bookkeeping for up to 10 businesses.
                                <br/><br/><strong>£48 /mo</strong>
                            </Card.Text>
                            <Button href='/signup' variant="primary">Try for free</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={12} className='mb-2'>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title>50 Clients</Card.Title>
                            <Card.Text>
                                Manage the bookkeeping for up to 50 businesses.
                                <br/><br/><strong>£98 /mo</strong>
                            </Card.Text>
                            <Button href='/signup' variant="primary">Try for free</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={12}>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title>Unlimited</Card.Title>
                            <Card.Text>
                                Manage the bookkeeping for unlimited businesses.
                                <br/><br/><strong>£178 /mo</strong>
                            </Card.Text>
                            <Button href='/signup' variant="primary">Try for free</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <strong><br/><br/><br/><br/>All subscription prices include VAT and offer unlimited document uploads, plus a 30 day free trial (cancel anytime)<br/><br/><br/><br/></strong>
            </Row>
            <Row className='support'>
                <Col>
                    <h1 id='support' className='mt-5 mb-4'>Support</h1>
                    <Row>
                        <Col></Col>
                        <Col lg={8}>
                        <h3>FAQs</h3><br/>
                            <Accordion>
                                <Accordion.Header><h5><strong>Is this only for UK invoices? 🇬🇧</strong></h5></Accordion.Header>
                                <Accordion.Body><h5>Currently data extraction only works with UK suppliers as the currency is set to GBP, but we are looking to expand to other markets and currencies in the near future!</h5></Accordion.Body>
                            </Accordion>
                            <br/>
                            <Accordion>
                                <Accordion.Header><h5><strong>When is integration with thrid party apps such as Xero expected?</strong></h5></Accordion.Header>
                                <Accordion.Body><h5>We are scheduled to release the next version of our web app in April 2023 that will include integration with all the major accounting suites</h5></Accordion.Body>
                            </Accordion>
                            <br/>
                            <Accordion>
                                <Accordion.Header><h5><strong>When are the mobile apps going to be available? 📱</strong></h5></Accordion.Header>
                                <Accordion.Body><h5>We are currently working on our iOS and Android apps that are expected to release in May 2023</h5></Accordion.Body>
                            </Accordion>
                            <br/><br/><br/>
                        </Col>
                        <Col></Col>
                    </Row>
                    <p>For any other queries, please email us at <strong><a style={{textDecoration:'none', color:'white'}} href='mailto:hello@cloud-entry.com'>hello@cloud-entry.com</a></strong><br/><br/></p><br/>
                </Col>
            </Row>
            <Row>
                <div className='footer'>
                    <a href="https://www.facebook.com/profile.php?id=100090766743974" class="mx-2" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands("facebook")} /></a>
                    <a href="https://twitter.com/_CloudEntry" class="mx-2" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands("twitter")} /></a> 
                    <a href="https://www.linkedin.com/company/cloudentry/" class="mx-2" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands("linkedin")} /></a> 
                    <br/><br/>Copyright © 2023 CloudEntry. All rights reserved.<br/>CloudEntry Ltd is a company registered in England and Wales. Company Registration Number 14772362.<br/><br/>
                    <Button variant='link' onClick={() => setTermsConditionsShow(true)}>Terms & Conditions</Button><t/>
                    <Button variant='link' onClick={() => setPrivacyPolicyShow(true)}>Privacy Policy</Button><t/>
                    <Button variant='link' onClick={() => setCookiePolicyShow(true)}>Cookie Policy</Button>
                </div>
            </Row>
            <Modal size="lg" show={termsConditionsShow} onHide={() => setTermsConditionsShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    These terms and conditions (the "Terms and Conditions") govern the use of <strong>cloud-entry.com</strong> (the "Site"). This Site is owned and operated by CloudEntry. This Site is a web service.<br/><br/>
                    By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.<br/><br/>
                    <strong>Intellectual Property</strong><br/>
                    All content published and made available on our Site is the property of CloudEntry and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.<br/><br/>
                    <strong>Acceptable Use</strong><br/>
                    As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:
                    <ul>
                        <li>Hack into the account of another user of the Site; or</li>
                        <li>Act in any way that could be considered fraudulent.</li>
                    </ul>
                    If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.<br/><br/>
                    <strong>Accounts</strong><br/>
                    When you create an account on our Site, you agree to the following:

                    <ol>
                        <li>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</li>
                        <li>All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.</li>
                    </ol>
                    We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.<br/><br/>
                    <strong>Sale of Services</strong><br/>
                    These Terms and Conditions govern the sale of services available on our Site.<br/><br/>
                    We are under a legal duty to supply goods that match the description of the good(s) you order on our Site.<br/><br/>
                    The following services are available on our Site:
                    <ul>
                        <li>Pro Subscription.</li>
                    </ul>
                    The services will be paid for in full when the services are ordered.<br/><br/>
                    These Terms and Conditions apply to all the services that are displayed on our Site at the time you access it. All information, descriptions, or images that we provide about our services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all services we provide. You agree to purchase services from our Site at your own risk.<br/><br/>
                    We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.<br/><br/>
                    <strong>Subscriptions</strong><br/>
                    Your subscription automatically renews and you will be automatically billed until we receive notification that you want to cancel the subscription.<br/><br/>
                    To cancel your subscription, please do so through the Stripe portal.<br/><br/>
                    <strong>Payments</strong><br/>
                    We accept the following payment methods on our Site:
                    <ul>
                        <li>Stripe portal.</li>
                    </ul>
                    When you provide us with your payment information, you authorise our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorise us to charge the amount due to this payment instrument.<br/><br/>
                    If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.<br/><br/>
                    <strong>Right to Cancel and Receive Reimbursement</strong><br/>
                    If you are a customer living in the United Kingdom or the Eurpoean Union you have the right to cancel your contract to purchase services from us within 14 days without giving notice. The cancellation period:
                    <ul>
                        <li>Will end 14 days from the date of purchase when you purchased a service.</li>
                    </ul>
                    To exercise your right to cancel you must inform us of your decision to cancel within the cancellation period. To cancel, contact us by email at hello@cloud-entry.com. You may use a copy of the Cancellation Form, found at the end of these Terms and Conditions, but you are not required to do so.<br/><br/>
                    The right to cancel does not apply to:
                    <ul>
                        <li>Goods or services, other than the supply of water, gas, electricity, or district heating, where the price depends upon fluctuations in the financial market that we cannot control and that may occur during the cancellation period;</li>
                        <li>Services that the customer has requested for the purpose of carrying out urgent repairs or maintenance;</li>
                        <li>Newspapers, magazines, or periodicals, except for subscriptions to such publications; and</li>
                        <li>Accommodation, transport of goods, vehicle rental services, catering, or services related to leisure activities, if the contract includes a specific date or period of performance.</li>
                    </ul>
                    <strong>Effects of Cancellation</strong><br/>
                    If you requested the performance of services begin during the cancellation period, you are required to pay us an amount which is in proportion to what has been performed until you have communicated to us your decision to cancel this contract. We will reimburse to you any amount you have paid above this proportionate payment.<br/><br/>
                    We will make the reimbursement using the same form of payment as you used for the initial purchase unless you have expressly agreed otherwise. You will not incur any fees because of the reimbursement.<br/><br/>
                    This right to cancel and to reimbursement is not affected by any return or refund policy we may have.<br/><br/>
                    <strong>Consumer Protection Law</strong><br/>
                    Where the Sale of Goods Act 1979, the Consumer Rights Act 2015, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.<br/><br/>
                    <strong>Limitation of Liability</strong><br/>
                    CloudEntry and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.<br/><br/>
                    <strong>Indemnity</strong><br/>
                    Except where prohibited by law, by using this Site you indemnify and hold harmless CloudEntry and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.<br/><br/>
                    <strong>Applicable Law</strong><br/>
                    These Terms and Conditions are governed by the laws of the Country of England.<br/><br/>
                    <strong>Severability</strong><br/>
                    If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.<br/><br/>
                    <strong>Changes</strong><br/>
                    These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.<br/><br/>
                    <strong>Contact Details</strong><br/>
                    Please contact us if you have any questions or concerns. Our contact details are as follows:
                    <ul>
                        <li>hello@cloud-entry.com</li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={privacyPolicyShow} onHide={() => setPrivacyPolicyShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This privacy policy sets out how uses and protects any information that you give when you use this website. Is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. May change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.<br/><br/>
                    <strong>What we collect</strong><br/>
                    We may collect the following information:
                    <ul>
                        <li>Name</li>
                        <li>Contact information including email address</li>
                        <li>Demographic information such as postcode, preferences and interests</li>
                        <li>Other information relevant to customer surveys and/or offers</li>
                        <li>For the exhaustive list of cookies we collect see the List of cookies we collect section.</li>
                    </ul>
                    <strong>What we do with the information we gather</strong><br/>
                    We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                    <ul>
                        <li>Internal record keeping.</li>
                        <li>We may use the information to improve our services.</li>
                        <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                        <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
                        <li>Security</li>
                    </ul>
                    We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.<br/><br/>
                    <strong>PRIVACY:-</strong>
                    <ol>
                        <li>CloudEntry limited is committed to protecting your privacy and maintaining the security of any personal information received from you.  We strictly adhere to the requirements of the data protection legislation in the UK. The purpose of this statement is to explain to the client what personal information we collect and how we may use it. </li>
                        <li>When you order, we need to know your name, address, email address, email, phone number, business name, and payment details.  This allows us to process and fulfil your order. You have the option to withhold personal information that is not required for the order process. </li>
                        <li>We use your personal information for payment and billing purposes, and to keep you informed with new developments. </li>
                        <li>We do not store credit card details nor do we share customer details with any 3rd parties.</li>
                        <li>We do not sell, rent or exchange your personal information with any third party for commercial reasons, beyond the essential requirement for credit/debit card validation during purchase.</li>
                        <li>We will not provide any of your personal information to other companies or individuals without your permission. However, we may need to provide your name and delivery address to third parties that CloudEntry limited may use for the purposes of delivering specific Services to you (e.g. customer support).</li>
                        <li>We follow strict security procedures in the storage and disclosure of information which you have given us, to prevent unauthorised access in accordance with the UK data protection legislation.</li>
                        <li>As part of our commitment to providing the best possible service to our customers we record all telephone calls answered in our main office. This helps us to identify ways that we can provide you with a better service.</li>
                    </ol>                    

                    <strong>Your Legal Rights:-</strong><br/>
                    You have the right to:
                    <ul>
                        <li>Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                        <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                        <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                        <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                        <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                        <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                        <li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                        <li>We do not knowingly collect data relating to children.</li>
                        <li>No fee usually required You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</li>
                        <li>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</li>
                        <li>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</li>
                    </ul>
                    Please note that not all calls are answered through our main office so these calls may not be recorded.<br/><br/>

                    We record calls:
                    <ul>
                        <li>for staff training purposes, helping us to improve the quality of our customer service and to ensure the information we provide is consistent and accurate</li>
                        <li>for reporting on the types and numbers of enquiries we receive</li>
                        <li>so we can find ways to simplify our service to you, and</li>
                        <li>To ensure CloudEntry limited have an accurate record of your call, which may be needed to support any transactions that take place over the phone and/or if there is a dispute.</li>
                    </ul>
                    CloudEntry limited retains the right to use client names, completed projects and any preliminary designs for the purpose of design competitions, future publications as examples, educational purposes, marketing materials, and as part of the CloudEntry limited portfolio. Where applicable the client will be given any necessary credit for usage of the project elements<br/><br/>
                    CloudEntry limited retains the right to list clients, client works, projects and none personal data as part of our ongoing marketing and promotion. Where applicable the client will be given any necessary credit for usage of the project elements.<br/><br/>
                    <strong>How we use cookies</strong><br/>
                    A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.<br/><br/>
                    We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.<br/><br/>
                    Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.<br/><br/>
                    <strong>Links to other websites</strong><br/>
                    Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.<br/><br/>
                    <strong>Controlling your personal information</strong><br/>
                    You may choose to restrict the collection or use of your personal information in the following ways: 
                    <ul>
                        <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
                        <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@cloud-entry.com</li>
                        <li>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</li>
                        <li>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to.</li>
                        <li>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={cookiePolicyShow} onHide={() => setCookiePolicyShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cookie Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This is the Cookie Policy for CloudEntry, accessible from <a href='https://cloud-entry.com/'>https://cloud-entry.com/</a><br/><br/>
                    <strong>What Are Cookies</strong><br/>
                    As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.<br/><br/>
                    For more general information on cookies see the Wikipedia article on HTTP Cookies.<br/><br/>
                    <strong>How We Use Cookies</strong><br/>
                    We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.<br/><br/>
                    <strong>Disabling Cookies</strong><br/>
                    You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.<br/><br/>
                    <strong>The Cookies We Set</strong><br/>
                    <ul>
                        <li>
                        Forms related cookies
                        When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.
                        </li>
                        <li>
                        Site preferences cookies
                        In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.
                        </li>
                    </ul>
                    <strong>Third Party Cookies</strong><br/>
                    In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.
                    <ul>
                        <li>
                        This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.
                        For more information on Google Analytics cookies, see the official Google Analytics page.
                        </li>
                        <li>
                        We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites including; Facebook, Twitter and LinkedIn, will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.
                        </li>
                    </ul>
                    <strong>More Information</strong><br/>
                    Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the Cookies Policy Template Generator and the Terms and Conditions Template.<br/><br/>
                    However if you are still looking for more information then you can contact us through one of our preferred contact methods:
                    <ul>
                        <li>
                            Email: hello@cloud-entry.com
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    )
}
