import React from 'react'

export default function NotFound() {
  return (
    <div>
        <h1>Error 404</h1>
        Page not found
    </div>
  )
}
