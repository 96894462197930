import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import PrivateRoute from './components/PrivateRoute';
import Main from './components/Main';
import AwaitVerification from './components/AwaitVerification';
import NotFound from './components/NotFound';
import PrivateSandbox from './components/PrivateSandbox'
import Sandbox from './components/Sandbox'
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
        <Router>
          <AuthProvider>
            <Routes>
              <Route exact path='/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>} />
              <Route exact path='/sandbox' element={<PrivateSandbox><Sandbox/></PrivateSandbox>} />
              <Route exact path='/await-verification' element={<AwaitVerification/>} />
              <Route exact path='/' element={<Main/>} />
              <Route exact path='' element={<Main/>} />
              <Route path='/signup' element={<Signup/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/forgot-password' element={<ForgotPassword/>} />
              <Route path='*' status={404} element={<NotFound/>}/>
            </Routes>
          </AuthProvider>
        </Router>
  );
}

export default App;
