// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";

const app = initializeApp({
  apiKey: 'AIzaSyDB-RD-7m7s0jZ9RMD9MkbYe0G0LceGuoc',
  authDomain: 'invoicedog-a6a57.firebaseapp.com',
  databaseURL: 'https://invoicedog-a6a57-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'invoicedog-a6a57',
  storageBucket: 'invoicedog-a6a57.appspot.com',
  messagingSenderId: '1023991187205',
  appId: '1:1023991187205:web:bf0e0fe2ff05dce70cd96c',
  measurementId: 'G-VKQ9WMS7RN'
});

// const analytics = getAnalytics(app);
export const storage = getStorage(app);

export const db = getDatabase(app);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const functions = getFunctions(app, 'europe-west1');