import { useState, useEffect, useRef, useMemo } from 'react';
import { storage, db, firestore, functions } from '../firebaseConfig';
import { ref as sRef, uploadBytesResumable, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { ref as dbRef, equalTo, query, orderByChild, remove, set, get, onValue } from 'firebase/database';
import { addDoc, collection, onSnapshot, query as fquery, where, getDocs } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions';
import { v4 } from 'uuid';
import { useAuth } from '../contexts/AuthContext';
import Pagination from './Pagination';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Button, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Scatter, Pie, Bar } from 'react-chartjs-2';
import { CategoryScale, Chart, LinearScale, LineElement, PointElement, TimeScale, BarElement, Title, Tooltip, ArcElement, Legend } from "chart.js";
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';

import '../custom.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

import { CSVLink } from "react-csv";
var XLSX = require("xlsx");

const PDFJS = require("pdfjs-dist/webpack");


export default function Dashboard() {
    const [dataList, setDataList] = useState([{file: 'file', category: 'category', description: 'description', selected: false}]);
    const [totalSpentDataList, setTotalSpentDataList] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hideLoadingRow, setHideLoadingRow] = useState(true);
    const [modalImg, setModalImg] = useState('');
    const [activeData, setActiveData] = useState({});
    const [dbUsername, setDbUsername] = useState('');
    const [direction, setDirection] = useState('desc');
    const [sortCriteria, setSortCriteria] = useState('Date');
    const [grandTotal, setGrandTotal] = useState(0);
    const [dataset, setDataset] = useState([]);
    const [chartTitle, setChartTitle] = useState('');
    const [showLine, setShowLine] = useState(false);
    const [showPie, setShowPie] = useState(false);
    const [showBar, setShowBar] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [pieLabels, setPieLabels] = useState([]);
    const [pieGraphData, setPieGraphData] = useState([]);
    const [dataSetSelectionName, setDataSetSelectionName] = useState('');
    const [barSelectorDisabled, setBarSelectorDisabled] = useState(false);
    const [pieSelectorDisabled, setPieSelectorDisabled] = useState(true);
    const [hidePieBarSwitch, setHidePieBarSwitch] = useState(true);
    const [hideInvoices, setHideInvoices] = useState(false);
    const [hideDataInsights, setHideDataInsights] = useState(true);
    const [csvData, setCSVData] = useState([]);
    const [activeDeleteData, setActiveDeleteData] = useState({});
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [fileRefPopulated, setFileRefPopulated] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [userSubscription, setUserSubscription] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [categoryColor, setCategoryColor] = useState({});
    const [showRemoveCategoryAlert, setShowRemoveCategoryAlert] = useState(false);
    const [categoryToRemove, setCategoryToRemove] = useState('');
    const [loadingStripe, setLoadingStripe] = useState(false);
    const [oldDataList, setOldDataList] = useState([]);
    const [selectAllState, setSelectAllState] = useState(false);
    const [numberSelected, setNumberSelected] = useState(0);
    const [activeClient, setActiveClient] = useState('');
    const [clients, setClients] = useState([]);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [activeDepartment, setActiveDepartment] = useState('Costs');
    const [suppliers, setSuppliers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [progress, setProgress] = useState([]);
    const [costsInvoices, setCostsInvoices] = useState([]);
    const [salesInvoices, setSalesInvoices] = useState([]);
    const [showExpenseReport, setShowExpenseReport] = useState(false);
    const [showPasswordChangeError, setShowPasswordChangeError] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const [showPasswordChangeSuccess, setShowPasswordChangeSuccess] = useState(false);
    const [pdfStyles] = useState(StyleSheet.create({
        page: {
            backgroundColor: 'white',
            fontFamily: 'Open Sans',
            paddingHorizontal: 10
        },
        section: {
            margin: 30,
            padding: 10
        },
        table: {
            width: '100%',
          },
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #EEE',
            paddingTop: 8,
            paddingBottom: 8,
        },
        header: {
            borderTop: 'none',
        },
        bold: {
            fontWeight: 600,
        },
        // So Declarative and unDRY 👌
        row1: {
            width: '25%',
        },
        row2: {
            width: '30%',
        },
        row3: {
            width: '15%',
        },
        row4: {
            width: '15%',
        },
        row5: {
            width: '15%',
        },
    }));

    useEffect(() => {
        Font.register({
            family: 'Open Sans',
            fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
            ]
            });
    }, [])

    const dateRef = useRef();
    const totalRef = useRef();
    const vatRef = useRef();
    const categoryRef = useRef();
    const supplierRef = useRef();
    const customerRef = useRef();
    const updateDescriptionRef = useRef();
    const uploadImageRef = useRef();
    const uploadCategoryRefs = useRef([]);
    const uploadDescriptionRefs = useRef([]);
    const newCategory = useRef();
    const newCategoryColor = useRef();
    const searchText = useRef();
    const canvasRef = useRef();
    const newClientNameRef = useRef();
    const updateClientNameRef = useRef();
    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();

    const { currentUser, logout, changePassword } = useAuth();

    const expand = 'lg';
    const navigate = useNavigate();

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = dataList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(dataList.length / recordsPerPage);

    async function handleLogout() {
        await logout();
        navigate('/');
    }

    async function handleChangePassword(currentPassword, newPassword) {
        setShowPasswordChangeError(false);
        setShowPasswordChangeSuccess(false);
        currentPasswordRef.current.value = '';
        newPasswordRef.current.value = '';
        try{
            await changePassword(currentPassword, newPassword);
            setShowPasswordChangeSuccess(true);
        } catch (err) {
            setShowPasswordChangeError(true);
            setPasswordChangeError(err.toString().replace('FirebaseError: Firebase: ', ''));
        }
    }

    const exportToExcel = (e) => {
        e.preventDefault();

        const sortedDataList = [...dataList].sort(function(a,b){ return a.date - b.date; });
        const filtered = sortedDataList.map(obj => (
            {[activeDepartment==='Costs' ? 'Supplier': 'Customer']: (activeDepartment==='Costs' ? obj.entities?.supplier.word: obj.entities?.customer.word), 
                Date: `${obj.date?.toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}`, 
                Total: obj.entities?.total.word, 
                Tax: obj.entities?.vat.word,
                Category: obj.category,
                Description: obj.description
            }));

        const ws = XLSX.utils.json_to_sheet(filtered);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, "cloudEntry.xlsx");
    }

    Chart.register(CategoryScale, LinearScale, BarElement, TimeScale, LineElement, PointElement, Title, Tooltip, ArcElement, Legend);

    if (dbUsername === '') {
        setDbUsername(currentUser.email.replace(/\./g, ","));
    }

    function setDatasetSpentPerInvoice() {
        setShowLine(true);
        setShowPie(false);
        setShowBar(false);
        setDataset([
            {
                data: dataList.map(({date, entities}) => ({x: date, y: entities?.total.word, supplier: entities?.supplier.word})),
                borderColor: "#36A2EB",
                showLine: true
            }]);
        setChartTitle('Invoice totals');
        setDataSetSelectionName('Invoice totals');
        setHidePieBarSwitch(true);
    }

    function setDatasetTotalSpent() {
        setDirection('desc');
        setSortCriteria('Date');
        setShowLine(true);
        setShowPie(false);
        setShowBar(false);
        setDataset([{
                data: totalSpentDataList.map(({date, cumulative, supplier}) => ({x: date, y: cumulative, supplier: supplier})),
                borderColor: "#FF5733",
                showLine: true
            }]);
        setChartTitle('Cumulative');
        setDataSetSelectionName('Cumulative');
        setHidePieBarSwitch(true);
    }

    function setGraphToSupplierPie() {
        setShowLine(false);
        setShowPie(true);
        setShowBar(false);
        setPieLabels(Object.keys(supplierData));
        setPieGraphData(Object.values(supplierData));
        setDataSetSelectionName('Per supplier');
        setHidePieBarSwitch(false);
        setBarSelectorDisabled(false);
        setPieSelectorDisabled(true);
    }

    function setGraphToCustomerPie() {
        setShowLine(false);
        setShowPie(true);
        setShowBar(false);
        setPieLabels(Object.keys(customerData));
        setPieGraphData(Object.values(customerData));
        setDataSetSelectionName('Per customer');
        setHidePieBarSwitch(false);
        setBarSelectorDisabled(false);
        setPieSelectorDisabled(true);
    }

    function setGraphToCategoryPie() {
        setShowLine(false);
        setShowPie(true);
        setShowBar(false);
        setPieLabels(Object.keys(categoryData));
        setPieGraphData(Object.values(categoryData));
        setDataSetSelectionName('Per category');
        setHidePieBarSwitch(false);
        setBarSelectorDisabled(false);
        setPieSelectorDisabled(true);
    }

    const showBarChart = () => {
        setBarSelectorDisabled(true);
        setPieSelectorDisabled(false);
        setShowPie(false);
        setShowBar(true);
    }

    const showPieChart = () => {
        setBarSelectorDisabled(false);
        setPieSelectorDisabled(true);
        setShowPie(true);
        setShowBar(false);
    }

    const searchInvoices = e => {
        e.preventDefault();
        if (!searchText.current.value) return;
        setOldDataList(dataList);
        const results = [];
        let categoryResults = dataList.filter((d) => d.category.toLowerCase() === searchText.current.value.toLowerCase());
        let descriptionResults = dataList.filter((d) => (d.description || '').toLowerCase().includes(searchText.current.value.toLowerCase()));
        let supplierResults = dataList.filter((d) => d.entities.supplier.word.toLowerCase() === searchText.current.value.toLowerCase());
        results.push(...categoryResults);
        results.push(...descriptionResults);
        results.push(...supplierResults);
        setDataList(results);
    }

    const clearSearch = e => {
        e.preventDefault();
        searchText.current.value='';
        if (oldDataList.length > 0) {
            setDataList(oldDataList);
        }
    }

    function checkMimeType(type) {
        if (type === 'image/jpeg' || type === 'image/png' || type === 'application/pdf') {
            return false;
        } else {
            alert('Unable to upload image: file is not on JPEG, PNG or PDF format');
            return  true;
        }
    }

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const convertPdfToImage = async (file) => {
        const images = [];
        const data = await readFileData(file);
        const pdf = await PDFJS.getDocument(data).promise;
        const canvas = document.createElement("canvas");
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
        canvas.remove();
        return images[0];
    }

    function uploadImageFile(uploadFile, category, description, index) {
        const filename = `${currentUser.email}/${activeDepartment}_${v4()}|||client|||${activeClient}|||client|||___category___${category}___category___${uploadFile.name ?? 'image.png'}`;
        const imageRef = sRef(storage, filename);
        const uploadTask = uploadBytesResumable(imageRef, uploadFile);
        uploadTask.then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                const dataRef = query(dbRef(db, `${dbUsername}/${activeClient}/${activeDepartment}`), orderByChild('file'), equalTo(filename));
                onValue(dataRef, (snapshot) => {
                    if (snapshot.val() != null) {
                        const response = Object.values(snapshot.val())[0];
                        const obj = {
                            file: filename,
                            url: url,
                            date: new Date(`${response.entities.date.word.split('/')[1]}/${response.entities.date.word.split('/')[0]}/${response.entities.date.word.split('/')[2]}`),
                            description: description ?? '',
                            selected: false,
                            category: response.category,
                            entities: response.entities,
                        }
                        setDataList((prev) => [...prev, obj]);
                        setHideLoadingRow(true);
                        setIsLoading(false);
                    }
                });
            });
        }).then(() => {
            const targetRef = query(dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}/descriptions`));
            get(targetRef).then((snapshot) => {
                const descriptions = snapshot.val() || {};
                set(targetRef, {...descriptions, [filename.split('/')[1].split('.')[0]]: description});
            });
        });
        uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(prev => {
                const newProgress = [...prev]
                newProgress[index] = progress.toFixed(2)
                return newProgress;
            });
        });
    }

    const uploadImage = event => {
        event.preventDefault();
        setFileRefPopulated(false);
        setHideLoadingRow(false);
        setIsLoading(true);
        setProgress([]);
        Array.from(uploadImageRef.current.files).forEach((uploadFile, index) => {
            const category = uploadCategoryRefs.current[index].value;
            const description = uploadDescriptionRefs.current[index].value;
            if (uploadFile == null || checkMimeType(uploadFile.type) || checkFilesizeLimitExceeded(uploadFile.size)) {
                setHideLoadingRow(true);
                setIsLoading(false);
                event.target.reset();
                return;
            }
            if (uploadFile.type === 'application/pdf') {
                convertPdfToImage(uploadFile).then(newFile => {
                    // uploadImageFile(newFile, index);
                    fetch(newFile).then(f => {
                        f.blob().then(b => {
                            uploadImageFile(b, category, description, index);
                        })
                    });
                });
            } else {
                uploadImageFile(uploadFile, category, description, index);
            }
        });
        setSelectedFiles([]);
        event.target.reset();
    };

    useMemo(() => {// get user subscription  
        if (activeClient) {
            async function getUserSubscription() {
                let subscription = '';
                const q = fquery(collection(firestore, `customers/${currentUser.uid}/subscriptions`), where('status', 'in', ['trialing', 'active']));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.items[0].plan.product === 'prod_NbPI0PQZfwdikp') {
                        subscription = 'Single Business';
                    }
                    if (data.items[0].plan.product === 'prod_NbPIc9iNrmWaI4') {
                        subscription = '10 Clients';
                    }
                    if (data.items[0].plan.product === 'prod_NbPIMLSLKnrFhh') {
                        subscription = '50 Clients';
                    }
                    if (data.items[0].plan.product === 'prod_NbPIqhjAHykHVq') {
                        subscription = 'Unlimited';
                    }
                });
                return subscription;
            }
            getUserSubscription().then((result) => {
                setUserSubscription(result);
            });

            // get the user's custom categories (& colors)
            const dbCategoryRef = query(dbRef(db, `${dbUsername}/${activeClient}/categories`));
            get(dbCategoryRef).then((snapshot) => {
                setCategoryColor(snapshot.val());
            });
        }

        // eslint-disable-next-line
    }, [activeClient]);

    function checkFilesizeLimitExceeded(filesize) {
        if (filesize > 4000000) {
            alert('Unable to upload image: file is larger than 4 MB limit');
            return true;
        } else {
            return false;
        }
    }

    function formatStorage(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1000
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function sortTheDataList() {
        if (sortCriteria === 'Date' && direction === 'desc') {
            dataList.sort(function(a,b){
                return b.date - a.date;
            });
        } 
        if (sortCriteria === 'Date' && direction === 'asc') {
            dataList.sort(function(a,b){
                return a.date - b.date;
            });
        }
        if (sortCriteria === 'Total' && direction === 'desc') {
            dataList.sort(function(a,b){
                return b.entities.total.word - a.entities.total.word;
            });
        }
        if (sortCriteria === 'Total' && direction === 'asc') {
            dataList.sort(function(a,b){
                return a.entities.total.word - b.entities.total.word;
            });
        }
        if (sortCriteria === 'Category' && direction === 'desc') {
            dataList.sort(function(a,b){
                return b.category.localeCompare(a.category);
            });
        }
        if (sortCriteria === 'Category' && direction === 'asc') {
            dataList.sort(function(a,b){
                return a.category.localeCompare(b.category);
            });
        }
        if (sortCriteria === 'Supplier' && direction === 'desc') {
            dataList.sort(function(a,b){
                return b.entities.supplier.word.localeCompare(a.entities.supplier.word);
            });
        }
        if (sortCriteria === 'Supplier' && direction === 'asc') {
            dataList.sort(function(a,b){
                return a.entities.supplier.word.localeCompare(b.entities.supplier.word);
            });
        }
        setDataList(dataList);
    }

    // useMemo to sort dataList before render by date desc (default)
    useMemo(()=>{
        sortTheDataList();
        // set grand total
        let calculatedTotal = 0;
        // eslint-disable-next-line
        dataList.map(({entities}) => {calculatedTotal+= Number(entities?.total.word)});
        setGrandTotal((Math.round(calculatedTotal * 100) / 100).toFixed(2));

        const sortedDataList = [...dataList].sort(function(a,b){ return a.date - b.date; });
        const result = sortedDataList.map((obj, i, self) => {
            if (i===0) {
                obj.cumulative = parseFloat(obj.entities?.total.word).toFixed(2);
                return obj;
            }
            const prevO = self[i-1];
            if (prevO) obj.cumulative = (parseFloat(prevO.cumulative) + parseFloat(obj.entities.total.word)).toFixed(2);
            return obj;
        });
        setTotalSpentDataList(result);

        // set piedata
        let categoryData = {};
        // eslint-disable-next-line
        result.map(({category, entities}) => {
            if (Object.keys(categoryData).includes(category)) {
                categoryData[category] += Number(entities?.total.word); 
            } else {
                categoryData[category] = Number(entities?.total.word);
            }
        });
        setCategoryData(categoryData);
        let supplierData = {};
        // eslint-disable-next-line
        result.map(({entities}) => {
            if (Object.keys(supplierData).includes(entities?.supplier.word)) {
                supplierData[entities?.supplier.word] += Number(entities?.total.word); 
            } else {
                supplierData[entities?.supplier.word] = Number(entities?.total.word);
            }
        });
        setSupplierData(supplierData);

        let customerData = {};
        // eslint-disable-next-line
        result.map(({entities}) => {
            if (Object.keys(supplierData).includes(entities?.customer.word)) {
                customerData[entities?.customer.word] += Number(entities?.total.word); 
            } else {
                customerData[entities?.customer.word] = Number(entities?.total.word);
            }
        });
        setCustomerData(customerData);
        
        setDatasetSpentPerInvoice();

        const csvSortedDataList = [...dataList].sort(function(a,b){ return a.date - b.date; });
        const csvFiltered = csvSortedDataList.map(({date, category, entities, description}) => (
            {[activeDepartment==='Costs' ? 'supplier' : 'customer']: activeDepartment==='Costs' ? entities?.supplier.word : entities?.customer.word, 
                date: `${date?.toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}`, 
                total: entities?.total.word, 
                tax: entities?.vat.word, 
                category: category,
                description: description
            }));
        setCSVData(csvFiltered);
        // eslint-disable-next-line
    },[dataList, direction, sortCriteria]);

    const deleteImage = (e) => {
        e.preventDefault();
        activeDeleteData.forEach(d => {
            const imageRef = sRef(storage, d.file);
            deleteObject(imageRef).then(() => {
                const imgRef = query(dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}`), orderByChild('file'), equalTo(d.file));
                get(imgRef).then((snapshot) => {
                    const imgKey = Object.keys(snapshot.val())[0];
                    const targetRef = dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}/${imgKey}`);
                    remove(targetRef);
                });
            }).catch((err) => {
                console.log(err);
            });
            const k = d.file.split('/')[1].split('.')[0];
            const descRef = query(dbRef(db, `${dbUsername}/${activeClient}/${activeDepartment}/descriptions`));
            get(descRef).then((snapshot) => {
                const description = {...snapshot.val()};
                if (k in description) {
                    delete description[k];
                    set(dbRef(db, `${dbUsername}/${activeClient}/${activeDepartment}/descriptions`), {...description});
                }
            });
            const index = dataList.indexOf(d);
            if (index > -1) {
                dataList.splice(index, 1); 
            }
            const newGrandTotal = (grandTotal - Number(d.entities.total.word)).toFixed(2);
            setGrandTotal(newGrandTotal);
        });
        setShowDeleteModal(false);
        setNumberSelected(0);
        setSelectAllState(false);
    }

    function handleEdit(data) {
        setModalImg(data.url);
        setActiveData(data);
        setShowEditModal(true);
    }

    function updateFirebase(obj) {
        const dataRef = query(dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}`), orderByChild('file'), equalTo(obj.file));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}/${dataKey}`);
            set(targetRef, obj);
        });
    }

    function updateData() {
        let newData = {...activeData};
        let newEntities = {...newData.entities};
        
        if (dateRef.current.value) {
            const parts = dateRef.current.value.split('-');
            const parsedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            newEntities = {
                ...newEntities,
                date: { word: parsedDate }
            }
            newData.entities = newEntities;
        }
        if (totalRef.current.value) {
            newEntities = {
                ...newEntities,
                total: { word: totalRef.current.value }
            }
            newData.entities = newEntities;
        }
        if (vatRef.current.value) {
            newEntities = {
                ...newEntities,
                vat: { word: vatRef.current.value }
            }
            newData.entities = newEntities;
        }
        if (categoryRef.current.value) {
            newData = {
                ...newData,
                category: categoryRef.current.value
            }
        }
        if (activeDepartment==='Costs' && supplierRef.current.value) {
            newEntities = {
                ...newEntities,
                supplier: { word: supplierRef.current.value }
            }
            newData.entities = newEntities;
        }
        if (activeDepartment==='Sales' && customerRef.current.value) {
            newEntities = {
                ...newEntities,
                customer: { word: customerRef.current.value }
            }
            newData.entities = newEntities;
        }
        const newDescription = updateDescriptionRef.current.value;
        newData = {
            ...newData,
            description: newDescription
        }
        const targetRef = query(dbRef(db, `/${dbUsername}/${activeClient}/${activeDepartment}/descriptions`));
        get(targetRef).then((snapshot) => {
            const descriptions = snapshot.val() || {};
            set(targetRef, {...descriptions, [newData.file.split('/')[1].split('.')[0]]: newDescription});
        });
        const index = dataList.indexOf(activeData);
        if (index > -1) {
            dataList.splice(index, 1); 
        }

        if (newData.entities.date && typeof newData.entities.date.word === 'string') {
            const localNewData = {
                ...newData,
                date: new Date(`${newData.entities.date.word.split('/')[1]}/${newData.entities.date.word.split('/')[0]}/${newData.entities.date.word.split('/')[2]}`)
            }
            setDataList((prev) => [...prev, localNewData]);
        } else {
            setDataList((prev) => [...prev, newData]);
        }
        setShowEditModal(false);
        updateFirebase(newData);
    }

    const handleDeleteClose = () => setShowDeleteModal(false);

    const changeDirection = () => {
        direction === 'asc' ? setDirection('desc') : setDirection('asc');
    }

    function onSelectFile() {
        setFileRefPopulated(uploadImageRef.current.files.length);
        if (!uploadImageRef.current.files || uploadImageRef.current.files.length === 0) {
            setSelectedFiles([]);
            return;
        }
        setSelectedFiles(Array.from(uploadImageRef.current.files));
    }

    const lineData = {
        label: 'blah',
        datasets: dataset
    }

    const pieData = {
        labels: pieLabels,
        datasets: [
          {
            label: 'Spend',
            data: pieGraphData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: chartTitle
            },
            tooltip: {
                callbacks: {
                    title: (context)=>{
                        return context[0].raw.supplier || 'None';
                      },
                    label: (tooltipItem)=>{
                        return '£' + tooltipItem.parsed.y;
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'dd T'
                },
                adapters: { 
                    date: {
                        locale: enUS, 
                    }
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    source:'auto',
                }
            },
            y: {
                title: {
                  display: true,
                  text: 'Pounds (£)'
                }
              }
        }
    }

    async function changeMembership(price) {
        setLoadingStripe(true);
        const docRef = await addDoc(collection(firestore, `customers/${currentUser.uid}/checkout_sessions`), 
            {
                price: price,
                success_url: 'http://cloud-entry.com/dashboard',
                cancel_url: 'http://cloud-entry.com/dashboard',
                trial_from_plan: !userSubscription,
                create_prorations: true,
            });
            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url);
                }
            });
    }

    async function manageMembership() {
        setLoadingStripe(true);
        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        const { data } = await functionRef({
            returnUrl: 'http://cloud-entry.com/dashboard'
        });
        window.location.assign(data.url);
    }

    const updateCategoryColors = () => {
        const dbCategoryRef = query(dbRef(db, `${dbUsername}/${activeClient}/categories`));
        get(dbCategoryRef).then((snapshot) => {
            const category = snapshot.val() || {};
            set(dbCategoryRef, {...category, [newCategory.current.value]: newCategoryColor.current.value});
        });
        setCategoryColor({...categoryColor, [newCategory.current.value]: newCategoryColor.current.value});
    }

    const removeCategory = () => {
        setShowRemoveCategoryAlert(false);
        delete categoryColor[categoryToRemove];
        setCategoryColor({...categoryColor});
        set(dbRef(db, `${dbUsername}/${activeClient}/categories`), categoryColor);
    }

    const getMeta = (url, callback) => {
        const img = new Image();
        img.src = url;
        img.onload = function() { callback(this.width, this.height); }
    };

    const resizeCanvasImg = () => {
        getMeta(activeData.url, (width, height) => {
            const wm = 450/width;
            const hm = 600/height;
            const context = canvasRef.current.getContext("2d");
            const dateBox = activeData.entities.date;
            const supplierBox = activeData.entities.supplier;
            const customerBox = activeData.entities.customer;
            const totalBox = activeData.entities.total;
            const vatBox = activeData.entities.vat;
            context.lineWidth = 1;
            context.strokeStyle = "blue";
            context.strokeRect(dateBox.x*wm, dateBox.y*hm, dateBox.w*wm, dateBox.h*hm);
            context.strokeStyle = "red";
            if (activeDepartment==='Costs') {
                context.strokeRect(supplierBox.x*wm, supplierBox.y*hm, supplierBox.w*wm, supplierBox.h*hm);
            } else {
                context.strokeRect(customerBox.x*wm, customerBox.y*hm, customerBox.w*wm, customerBox.h*hm);
            }
            context.strokeStyle = "orange";
            context.strokeRect(totalBox.x*wm, totalBox.y*hm, totalBox.w*wm, totalBox.h*hm);
            context.strokeStyle = "green";
            context.strokeRect(vatBox.x*wm, vatBox.y*hm, vatBox.w*wm, vatBox.h*hm);
        });
    }

    const handleSelectAll = () => {
        setSelectAllState(!selectAllState);
    }

    function handleSelectIndividual(e, obj) {
        e.stopPropagation();
        var newDataList = dataList.map(d => d === obj ? {...d, selected: !d.selected} : d);
        setDataList(newDataList);
    }

    useEffect(() => {
        var numSelected = 0;
        var allSelected = true;
        dataList.forEach(d => {
            if(!d.selected) {
                allSelected = false
            } else {
                numSelected++;
            }
        });
        setSelectAllState(allSelected);
        setNumberSelected(numSelected);
        if (dataList.length === 0) { setSelectAllState(false)}
    }, [dataList]);

    useEffect(() => {
        if (selectAllState) {
            setDataList(dataList.map(d => ({...d, selected: true})));
        } else {
            var allSelected = true;
            dataList.forEach(d => {
                if(!d.selected) {
                    allSelected = false
                }
            });
            if (allSelected) {
                setDataList(dataList.map(d => ({...d, selected: false})));
            }
        }
        // eslint-disable-next-line
    }, [selectAllState]);

    const handleDeleteButton = () => {
        var dataToDelete = [];
        dataList.forEach(d => {
            if(d.selected) {
                dataToDelete.push(d);
            }
        })
        setActiveDeleteData(dataToDelete);
        setShowDeleteModal(true);
    }

    useMemo(() => {
        if(dbUsername) {
            get(query(dbRef(db, dbUsername))).then((snapshot) => {
                setClients(Object.keys(snapshot.val()));
                setActiveClient(Object.keys(snapshot.val())[0]);
            });
        }
        // eslint-disable-next-line
    }, [dbUsername]);

    function populateDataList(item, url, dep, client) {
        const dataRef = query(dbRef(db, `${dbUsername}/${client}/${dep}`), orderByChild('file'), equalTo(item._location.path));
        get(dataRef).then((snapshot) => {
            setIsLoading(false);
            setHideLoadingRow(true);
            if (snapshot.val() != null) {
                const response = Object.values(snapshot.val())[0];
                const k = item._location.path.split('/')[1].split('.')[0];
                const targetRef = dbRef(db, `/${dbUsername}/${client}/${dep}/descriptions`);
                get(targetRef).then((snapshot) => {
                    const obj = {
                        file: item._location.path,
                        url: url,
                        date: new Date(`${response.entities.date.word.split('/')[1]}/${response.entities.date.word.split('/')[0]}/${response.entities.date.word.split('/')[2]}`),
                        description: snapshot.val() ? snapshot.val()[k] : '',
                        selected: false,
                        category: response.category,
                        entities: response.entities,
                    }
                    dep === 'Costs' ? setCostsInvoices((prev) => [...prev, obj]) : setSalesInvoices((prev) => [...prev, obj]);
                });
            }
        });
    }

    useEffect(() => {
        setDataList([]);
        if (activeClient && dbUsername) {
            setActiveDepartment('Costs');
            setCostsInvoices([]);
            setSalesInvoices([]);
            const imageListRef = sRef(storage, `${currentUser.email}/`);
            listAll(imageListRef).then((response) => {
                response.items.forEach(async (item) => {
                    await getDownloadURL(item).then((url) => {
                        const dep = item._location.path.split('/')[1].substring(0,5);
                        populateDataList(item, url, dep, activeClient);
                    });
                });
            });
        }
        // eslint-disable-next-line
    }, [activeClient]);

    useEffect(() => {
        setDataList([]);
        activeDepartment === 'Costs' ?  setDataList(costsInvoices) : setDataList(salesInvoices);
        // eslint-disable-next-line
    }, [activeDepartment, activeClient, costsInvoices, salesInvoices]);

    useEffect(() => {
        setSuppliers([]);
        setCustomers([]);
        if(activeClient) {
            get(dbRef(db, `/${dbUsername}/${activeClient}/Costs`)).then((snapshot) => {
                if(snapshot.val()) {
                    setSuppliers([...new Set(Object.values(snapshot.val()).map(a=>a.entities?.supplier.word))]);
                }
            });
            get(dbRef(db, `/${dbUsername}/${activeClient}/Sales`)).then((snapshot) => {
                if(snapshot.val()) {
                    setCustomers([...new Set(Object.values(snapshot.val()).map(a=>a.entities?.customer.word))]);
                }
            });
        }
    }, [dataList, activeClient]); //eslint-disable-line

    function addNewClient() {
        if (!clients.includes(newClientNameRef.current.value)) {
            const newClient = newClientNameRef.current.value;
            set(dbRef(db, `${dbUsername}/${newClient}/categories`), {
                Miscellaneous: '#ff6961',
                Insurance: '#ffb480',
                Finance: '#f8f38d',
                Transportation: '#42d6a4',
                Services: '#08cad1',
                Utilities: '#59adf6',
                Food: '#9d94ff',
                Inventory: '#c780e8'
            }).then(() => {
                setDataList([]);
                setActiveClient(newClient);
                setClients(prev => [...prev, newClient]);
                setShowNewClientModal(false);
            });
        }
    }

    function deleteClient() {
        const targetRef = dbRef(db, `/${dbUsername}/${activeClient}`);
        remove(targetRef);
        clients.splice(clients.indexOf(activeClient), 1);
        setClients([...clients]);
        setActiveClient(clients[0]);
    }

    function updateClientName() {
        const newClientName = updateClientNameRef.current.value.trim();
        if (!newClientName || !newClientName.match(/^[A-Za-z0-9 ]*$/)) {
            alert('Client name must only contain letters, numbers and spaces');
            return;
        }
        if (clients.includes(newClientName)) {
            alert('Client name must be unique');
            return;
        }
        setDataList([]);
        const clientRef = query(dbRef(db, `${dbUsername}/${activeClient}`));
        onValue(clientRef, (snapshot) => {
            remove(clientRef);
            set(dbRef(db, `${dbUsername}/${newClientName}`), snapshot.val()).then(() => {
                const nextClients = clients.splice(clients.indexOf(activeClient), 1);
                nextClients.push(newClientName);
                setClients(nextClients);
                setActiveClient(newClientName);
            });
        });
    }

    const ExpensesReport = () => (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
            <View style={[pdfStyles.bold, {paddingVertical: 20}]}>
                <Text>{`${activeClient} - Expense Report (${new Date().toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })})`}</Text>
            </View>
            <View style={[pdfStyles.bold, {paddingVertical: 20}]}>
                <Text>Total: £{(costsInvoices.map(a => Number(a?.entities?.total.word)).reduce((prev, next) => {return prev + next}, 0)).toFixed(2)}</Text>
            </View>
            <View style={[pdfStyles.table, {paddingVertical: 20}]}>
                <View style={[pdfStyles.row, pdfStyles.bold, pdfStyles.header]}>
                    <Text style={pdfStyles.row1}>Supplier</Text>
                    <Text style={pdfStyles.row2}>Date</Text>
                    <Text style={pdfStyles.row3}>Total</Text>
                    <Text style={pdfStyles.row4}>Tax</Text>
                    <Text style={pdfStyles.row5}>Net</Text>
                </View>
                {costsInvoices.map((row, i) => (
                    <View key={i} style={pdfStyles.row} wrap={false}>
                        <Text style={pdfStyles.row1}>{row.entities?.supplier.word}</Text>
                        <Text style={pdfStyles.row2}>{row.date?.toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                        <Text style={pdfStyles.row3}>£{row.entities?.total.word}</Text>
                        <Text style={pdfStyles.row4}>£{row.entities?.vat.word}</Text>
                        <Text style={pdfStyles.row5}>£{(Number(row.entities?.total.word) - Number(row.entities?.vat.word)).toFixed(2)}</Text>
                    </View>
                ))}
                <View style={pdfStyles.row}>
                    <Text style={pdfStyles.row1}></Text>
                    <Text style={[pdfStyles.row2, pdfStyles.bold]}>Expense total</Text>
                    <Text style={[pdfStyles.row3, pdfStyles.bold]}>£{(costsInvoices.map(a => Number(a?.entities?.total.word)).reduce((prev, next) => {return prev + next}, 0)).toFixed(2)}</Text>
                    <Text style={pdfStyles.row4}>£{(costsInvoices.map(a => Number(a?.entities?.vat.word)).reduce((prev, next) => {return prev + next}, 0)).toFixed(2)}</Text>
                    <Text style={pdfStyles.row5}>£{(costsInvoices.map(a => Number(a?.entities?.total.word)).reduce((prev, next) => {return prev + next}, 0)).toFixed(2)-(costsInvoices.map(a => Number(a?.entities?.vat.word)).reduce((prev, next) => {return prev + next}, 0)).toFixed(2)}</Text>
                </View>
            </View>
            <View>
                <Text style={[pdfStyles.bold, {paddingVertical: 20}]}>Approval</Text>
                <Text style={{paddingVertical: 10}}>Customer {'\xa0'.repeat(8)}<Text style={{textDecoration: 'underline', fontSize: 10}}> {'\xa0'.repeat(50)}(Signature)</Text>{'\xa0'.repeat(8)}<Text style={{textDecoration: 'underline', fontSize: 10}}> {'\xa0'.repeat(50)}(Print)</Text></Text>
                <Text style={{paddingVertical: 10}}>Approved by {'\xa0'.repeat(3)}<Text style={{textDecoration: 'underline', fontSize: 10}}> {'\xa0'.repeat(50)}(Signature)</Text>{'\xa0'.repeat(8)}<Text style={{textDecoration: 'underline', fontSize: 10}}> {'\xa0'.repeat(50)}(Print)</Text></Text>
            </View>
            </Page>
        </Document>
    )

    const expensesReport = (e) => {
        e.preventDefault();
        setShowExpenseReport(true);
    }
    
    return (
        <>
            <Modal show={showExpenseReport} fullscreen={true} onHide={() => {setShowExpenseReport(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Expenses Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer style={{height: '100%', width: '100%'}}>
                        <ExpensesReport />
                    </PDFViewer>
                </Modal.Body>
            </Modal>
            <Modal show={showNewClientModal} onHide={() => {setShowNewClientModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Form.Group className="mb-3" id="formClientName">
                                <FloatingLabel controlId="floatingSelect" label={'Name'}>
                                    <Form.Control type="text" ref={newClientNameRef} />
                                </FloatingLabel>
                            </Form.Group>
                            <Button onClick={() => {addNewClient()}} variant="primary">Add</Button>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={showUploadModal} id="upload-popover" onHide={()=> {setShowUploadModal(false); setSelectedFiles([])}}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload {activeDepartment} Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    JPEG, PNG or PDF files up to 4 MB<br/><br/>
                    <Form className='mb-3' onSubmit={!isLoading ? uploadImage : null}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control type="file" ref={uploadImageRef} onChange={onSelectFile} multiple/>
                        </Form.Group>
                        <ListGroup>
                        {selectedFiles.map((selectedFile, index) => {
                            return (
                                <>
                                    <ListGroup.Item>
                                    <h5><br/>{selectedFile.name}</h5>
                                    <FloatingLabel controlId="floatingSelect" label="Category">
                                        <Form.Select className='my-3' ref={(e) => uploadCategoryRefs.current[index] = e}>
                                            {Object.entries(categoryColor).map(([k, v]) => {
                                                return (
                                                    <option key={`category-color-${k}`}value={k}>{k}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingDescription" label="Description (optional)">
                                        <Form.Control ref={(e) => uploadDescriptionRefs.current[index] = e} as="textarea" aria-label="description" />
                                    </FloatingLabel>
                                    <Figure>
                                        <Figure.Image className={fileRefPopulated ? 'my-3' : ''} width='100' alt='' src={URL.createObjectURL(selectedFile)}/>
                                        <Figure.Caption>{fileRefPopulated ? formatStorage(selectedFile.size) : ''}</Figure.Caption>
                                    </Figure><br/>
                                    </ListGroup.Item>
                                </>  
                            )
                        })}
                        </ListGroup><br/>
                        <Button type='submit' disabled={fileRefPopulated < 1 || isLoading} >{isLoading ? 'Uploading…' : 'Upload'}</Button>
                        <br/><br/><hr/>
                        <p className='mt-3' style={{fontSize: 12}}>
                            You can also upload files by sending them to <strong>upload@cloud-entry.com</strong> from the email address associated with your account, 
                            by putting "type: [Costs|Sales], client: [Client name], category: [Category]" in the subject line and attaching the invoice as a JPEG, PNG or PDF file.
                        </p>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showSettingsModal} size='lg' onHide={()=> {setShowSettingsModal(false);}}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className="mb-3">
                        <Tab eventKey="Clients" title="Clients">
                            <Container>
                                <Row>
                                    <Col sm={12} lg={3}>
                                        <Dropdown className='mb-3'>
                                            <Dropdown.Toggle id="dropdown-basic">{activeClient}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {clients.map(client => {    // eslint-disable-line
                                                    if (client !== activeClient) {
                                                        return <Dropdown.Item onClick={() => {setDataList([]); setActiveClient(client);}} key={`set-active-${client}`}>{client}</Dropdown.Item>
                                                    }
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <InputGroup className='mb-3'>
                                            <Form.Control key={activeClient} ref={updateClientNameRef} defaultValue={activeClient}/>
                                            <Button variant="success" id="button-addon2" onClick={() => {updateClientName()}}>Update name</Button>
                                        </InputGroup>
                                        <Button className='mb-3' variant="danger" onClick={() => {deleteClient()}} disabled={clients.length === 1}>Delete client</Button>
                                    </Col>
                                    <Col sm={12} lg={3}>  
                                        <Button variant='success' disabled={(!userSubscription || userSubscription==='Single Business' || (userSubscription==='10 Clients' && clients.length > 9) || (userSubscription==='50 Clients' && clients.length > 49)) && currentUser.email !== 'jack.gee@protonmail.com'} onClick={() => {setShowNewClientModal(true); setShowSettingsModal(false);}}>Add new client <FontAwesomeIcon icon={solid("plus")} /></Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={false}></Col>
                                    <Col lg={6}>
                                        <h5 className='mt-4'>Categories</h5>
                                        <InputGroup className="mb-3">
                                            <Form.Control style={{width: '60%'}} ref={newCategory}/>
                                            <Form.Control type="color" id="exampleColorInput" defaultValue="#563d7c" ref={newCategoryColor}/>
                                            <Button variant="success" id="button-addon1" onClick={updateCategoryColors}>Add</Button>
                                        </InputGroup>
                                        {Object.entries(categoryColor).map(([k, v]) => {
                                            return (
                                                <><Badge key={`catregory-badge-${k}`} pill bg="light" text="dark" onClick={() => {if (k !== 'Miscellaneous') {setCategoryToRemove(k); setShowRemoveCategoryAlert(true);} else {setShowRemoveCategoryAlert(false)}}} className='mb-2 mx-2'><h5>{k} <Badge pill bg='' style={{backgroundColor: v}}> </Badge></h5></Badge></>
                                            )
                                        })}
                                    </Col>
                                    <Col sm={false}></Col>
                                </Row>
                                <Row className='mt-5'>
                                    <Col>
                                        <h5>Suppliers</h5>
                                        {suppliers.map(a=><p key={`suppliers-list-${a}`}>{a}</p>)}
                                    </Col>
                                    <Col>
                                        <h5>Customers</h5>
                                        {customers.map(a=><p key={`customers-list-${a}`}>{a}</p>)}
                                    </Col>
                                </Row>
                            </Container>
                            <Alert show={showRemoveCategoryAlert} variant='danger' onClose={() => setShowRemoveCategoryAlert(false)} dismissible rootClose>
                                Remove <strong>{categoryToRemove}</strong>? {' '}
                                <Alert.Link href="#" onClick={removeCategory}>Confirm</Alert.Link>
                            </Alert>
                        </Tab>
                        <Tab eventKey="Account" title="Account">
                            <Container>
                                <Row>
                                    <Col lg= {6} sm={12}>
                                        <strong>Email:</strong>&nbsp; {currentUser.email}<br/><br/>
                                        <FloatingLabel controlId="floatingCurrentPass" label={'Current password'}>
                                            <Form.Control key={'current-password'} type='password' ref={currentPasswordRef}/>
                                        </FloatingLabel><br/>
                                        <FloatingLabel controlId="floatingNewPass" label={'New password'}>
                                            <Form.Control key={'new-password'} type='password' ref={newPasswordRef}/>
                                        </FloatingLabel><br/>
                                        <Button id="change-password-button" className='mb-3' onClick={() => {handleChangePassword(currentPasswordRef.current.value, newPasswordRef.current.value)}}>Change password</Button>
                                        <Alert show={showPasswordChangeError} variant='danger' onClose={() => setShowPasswordChangeError(false)} dismissible rootClose>
                                            {passwordChangeError}
                                        </Alert>
                                        <Alert show={showPasswordChangeSuccess} variant='success' onClose={() => setShowPasswordChangeSuccess(false)} dismissible rootClose>
                                            Password changed successfully
                                        </Alert>
                                    </Col>
                                    <Col lg={6}></Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="Subscription" title="Subscription">
                            <Container className='mb-3'>
                                All subscription prices include VAT and offer unlimited document uploads<br/><br/>
                                <Row>
                                    <Col lg={3} sm={12} className='my-2'>
                                        <Card>
                                            <Card.Body>
                                                <h5>Single Business</h5>
                                                Manage the bookkeeping for your practice.<br/><br/><strong>£18 /mo</strong><br/><br/>
                                                <Button onClick={() => {changeMembership('price_1MqCTzApeE0FkSR8wbzBmDqQ')}} variant="success" disabled={userSubscription==='Single Business' || loadingStripe}>{userSubscription ? userSubscription==='Single Business' ? 'Current plan' : 'Switch' : 'Try for free'}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} className='my-2'>
                                        <Card>
                                            <Card.Body>
                                                <h5>10 Clients</h5>
                                                Manage the bookkeeping for up to 10 businesses.<br/><br/><strong>£48 /mo</strong><br/><br/>
                                                <Button onClick={() => {changeMembership('price_1MqCTsApeE0FkSR8UZgvcB73')}} variant="success" disabled={userSubscription==='10 Clients' || loadingStripe}>{userSubscription ? userSubscription==='10 Clients' ? 'Current plan' : 'Switch' : 'Try for free'}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} className='my-2'>
                                        <Card>
                                            <Card.Body>
                                                <h5>50 Clients</h5>
                                                Manage the bookkeeping for up to 50 businesses.<br/><br/><strong>£98 /mo</strong><br/><br/>
                                                <Button onClick={() => {changeMembership('price_1MqCTiApeE0FkSR8Ym0mDSfS')}} variant="success" disabled={userSubscription==='50 Clients' || loadingStripe}>{userSubscription ? userSubscription==='50 Clients' ? 'Current plan' : 'Switch' : 'Try for free'}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} className='my-2'>
                                        <Card>
                                            <Card.Body>
                                                <h5>Unlimited</h5>
                                                Manage the bookkeeping for unlimited businesses.<br/><br/><strong>£178 /mo</strong><br/><br/>
                                                <Button onClick={() => {changeMembership('price_1MqCTdApeE0FkSR8yOwZtkcV')}} variant="success" disabled={userSubscription==='Unlimited' || loadingStripe}>{userSubscription ? userSubscription==='Unlimited' ? 'Current plan' : 'Switch' : 'Try for free'}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                            <Button disabled={loadingStripe} onClick={manageMembership}>Manage subscription</Button>
                            <Spinner animation="border" variant="primary" hidden={!loadingStripe}/>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <Modal show={showEditModal} onHide={() => {setShowEditModal(false)}} size='lg' onShow={resizeCanvasImg}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <canvas 
                                    ref={canvasRef}
                                    width={450}
                                    height={600}
                                    style={{ background: `url(${modalImg})`, backgroundSize: '100% 100%' }}
                                />
                            </Col>
                            <Col>
                                <Form>
                                    {activeDepartment==='Costs' ? <Form.Group className="mb-3" id="formSupplier"><FloatingLabel controlId="floatingSelect" label={'Supplier'}><Form.Control type="text" ref={supplierRef} defaultValue={activeData.entities ? activeData.entities.supplier.word : ''}/></FloatingLabel></Form.Group> : <Form.Group className="mb-3" id="formCustomer"><FloatingLabel controlId="floatingSelect" label={'Customer'}><Form.Control type="text" ref={customerRef} defaultValue={activeData.entities ? activeData.entities.customer.word : ''}/></FloatingLabel></Form.Group>}
                                    <Form.Group className="mb-3" id="formTotal">
                                        <FloatingLabel controlId="floatingSelect" label={'Total'}>
                                            <Form.Control type="text" ref={totalRef} defaultValue={activeData.entities ? activeData.entities.total.word : ''}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="formVat">
                                        <FloatingLabel controlId="floatingSelect" label={'VAT'}>
                                            <Form.Control type="text" ref={vatRef} defaultValue={activeData.entities ? activeData.entities.vat.word : ''}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="formDate">
                                        <FloatingLabel controlId="floatingSelect" label="Date">
                                            <Form.Control type='date' ref={dateRef} defaultValue={activeData.date ? activeData.date.toISOString().split('T')[0] : undefined}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <FloatingLabel controlId="floatingSelect" label="Category">
                                        <Form.Select className='mt-3 mb-3' defaultValue={activeData.category} ref={categoryRef}>
                                            {Object.entries(categoryColor).map(([k, v]) => {
                                                return (
                                                    <option key={`category-color-${k}-2`} value={k}>{k}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <Form.Group className="mb-3" id="formDescription">
                                        <FloatingLabel controlId="floatingSelect" label={'Description'}>
                                            <Form.Control type="text" ref={updateDescriptionRef} defaultValue={activeData.description}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Button onClick={() => {updateData();}} variant="primary">Update</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Body>
            </Modal>
            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" id="formLabel">
                            <Form.Label>Are you sure you want to delete these invoices?</Form.Label>
                        </Form.Group>
                        <Button onClick={handleDeleteClose} variant="primary">No, go back</Button>
                        <Button onClick={(e) => {deleteImage(e)}} className='m-2' variant='danger'>Yes, delete</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Navbar key={expand} variant='dark' expand={expand} className="navbar-custom" fixed='top' collapseOnSelect>
                <Container fluid>
                    <Navbar.Brand href="#"><img src='/logo.svg' alt='' height='45' className='d-inline-block align-top mx-3'/></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas id={`offcanvasNavbar-dashboard`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
                        <Offcanvas.Header closeButton />
                        <Offcanvas.Body>
                            <Nav>
                                <NavDropdown title={activeClient} id="client-nav-dropdown">
                                    {clients.map(client => {    // eslint-disable-line
                                        if (client !== activeClient) {
                                            return <NavDropdown.Item onClick={() => {setHideLoadingRow(false); setIsLoading(true); setDataList([]); setActiveClient(client);}} key={`set-active-${client}-2`}>{client}</NavDropdown.Item>
                                        }
                                    })}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item disabled={(!userSubscription || userSubscription==='Single Business' || (userSubscription==='10 Clients' && clients.length > 9) || (userSubscription==='50 Clients' && clients.length > 49)) && currentUser.email !== 'jack.gee@protonmail.com'} onClick={() => {setShowNewClientModal(true)}}>Add new client <FontAwesomeIcon icon={solid("plus")} /></NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav>
                                <NavDropdown title={activeDepartment} id="department-nav-dropdown">
                                    {activeDepartment==='Costs' ? <></> : <NavDropdown.Item onClick={() => {setActiveDepartment('Costs'); setDataList([]);}}>Costs</NavDropdown.Item>}
                                    {activeDepartment==='Sales' ? <></> : <NavDropdown.Item onClick={() => {setActiveDepartment('Sales'); setDataList([]);}}>Sales</NavDropdown.Item>}
                                </NavDropdown>
                            </Nav>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link eventKey="1" onClick={() => {setHideDataInsights(true); setHideInvoices(false);}}>Home</Nav.Link>
                                <Nav.Link eventKey="2" onClick={() => {setHideDataInsights(false); setHideInvoices(true); setDirection('desc'); setSortCriteria('Date');}}>Data Insights</Nav.Link>
                                <NavDropdown title="Export" id="export-nav-dropdown" align="end">
                                    <NavDropdown.Item onClick={(e) => {expensesReport(e)}}><span className="normal-text">Expenses Report</span></NavDropdown.Item>
                                    <NavDropdown.Item onClick={(e) => {exportToExcel(e)}}><span className="normal-text">Excel file</span></NavDropdown.Item>
                                    <NavDropdown.Item as="button">
                                        <CSVLink data={csvData} headers={[
                                                activeDepartment==='Costs' ? { label: "Supplier", key: "supplier" } : { label: "Customer", key: "customer" },
                                                { label: "Date", key: "date" },
                                                { label: "Total", key: "total" },
                                                { label: "Tax", key: "tax" },
                                                { label: "Category", key: "category" },
                                                { label: "Description", key: "description" }
                                            ]} filename={"cloud-entry.csv"} className="normal-text" target="_blank">
                                            CSV file
                                        </CSVLink>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Account" id="basic-nav-dropdown" align="end">
                                    <NavDropdown.Item style={{ color: '#1B4571' }} disabled={true}>{currentUser.email}</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => {setShowSettingsModal(true);}}>Settings</NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <Container hidden={hideInvoices}>
                <Row>
                    <Col className='second-navbar'>
                        <Row>
                            <Col lg={2} md={1} sm={3} xs={4}>
                                <div className='center'><b>Total:&nbsp;</b>£{grandTotal}</div>
                            </Col>
                            <Col lg={1} md={1} sm={3} xs={4}>
                                <Button className="mt-2 ml-2 float-end" variant="success" onClick={() => {setShowUploadModal(true)}} disabled={!userSubscription && currentUser.email!=='jack.gee@protonmail.com'}><FontAwesomeIcon icon={solid("plus")} /></Button>
                            </Col>
                            <Col lg={1} md={2} sm={3} xs={3}>
                                <Button className="mt-2 mr-2 float-end" variant='danger' onClick={handleDeleteButton} disabled={!numberSelected}>Delete {numberSelected ? `(${numberSelected})` : ''}</Button>
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={7}>
                                <InputGroup className='search-bar float-end'>
                                    <Form.Control ref={searchText} aria-label="search field" aria-describedby="search-field" placeholder='Search'/>
                                    <button id="clear-search-button" onClick={(e) => clearSearch(e)}>X</button>
                                    <Button variant="outline-secondary" id="search-button" onClick={(e) => searchInvoices(e)}><FontAwesomeIcon icon={solid("magnifying-glass")} /></Button>
                                </InputGroup>
                            </Col>
                            <Col lg={2} md={2} sm={4} xs={5}>
                                <ButtonGroup aria-label="Basic example" className="mx-4 mt-2 float-end">
                                    <DropdownButton title={sortCriteria} as={ButtonGroup} variant="light">
                                        <Dropdown.Item onClick={() => {setSortCriteria('Date')}}>Date</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {setSortCriteria('Total')}}>Total</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {setSortCriteria('Category')}}>Category</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {setSortCriteria('Supplier')}}>Supplier</Dropdown.Item>
                                    </DropdownButton>
                                    <Button onClick={changeDirection} variant="light">{direction === 'asc' ? <FontAwesomeIcon icon={solid("sort-up")} /> : <FontAwesomeIcon icon={solid("sort-down")} />}</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className='custom-container'>
                    <Table responsive className='mt-3'>
                        <thead>
                            <tr key='tab-headers'>
                                <th key='tab-headers-checkde'><input type="radio" checked={selectAllState} onClick={handleSelectAll} onChange={e => {}}/></th>
                                <th key='tab-headers-supp-cust'>{activeDepartment==='Costs' ? 'Supplier' : 'Customer'}</th>
                                <th key='tab-headers-total'>Total</th>
                                <th key='tab-headers-vat'>Tax</th>
                                <th key='tab-headers-date'>Date</th>
                                <th key='tab-headers-category'>Category</th>
                                <th key='tab-headers-description'>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords.map(obj=> {
                                return  (<tr key={obj.file} className='data-row' onClick={() => {handleEdit(obj)}}>
                                            <td key={`${obj.file}-checked`}><input type="radio" checked={obj.selected} onClick={(e) => {handleSelectIndividual(e, obj)}} onChange={e => {}}/></td>
                                            {activeDepartment==='Costs' ? <td>{obj.entities?.supplier.word}</td> : <td>{obj.entities?.customer.word}</td>}
                                            <td key={`${obj.file}-total`}>£{obj.entities?.total.word}</td>
                                            <td key={`${obj.file}-vat`}>£{obj.entities?.vat.word}</td>
                                            <td key={`${obj.file}-date`}>{obj.date?.toLocaleDateString("en-GB", { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                                            <td key={`${obj.file}-category`}><Badge pill bg='' style={{backgroundColor: categoryColor[obj.category?.replaceAll(/[^A-Z0-9]/ig, '')]}}>{obj.category}</Badge></td>
                                            <td key={`${obj.file}-description`}>{obj.description}</td>
                                        </tr>);
                            })}
                            {progress.map((p, i )=> {
                                return (
                                    <tr hidden={hideLoadingRow} key={`loading-row-${i}`}>
                                        <td colSpan="7" key={`loading-col-${i}`}>{<ProgressBar now={p} />}</td>
                                    </tr>
                                )
                            })}
                            <tr hidden={dataList.length || isLoading} key='empty-row'>
                                <td colSpan="7" key='empty-col'><p className='mt-3'>Looking a bit empty... Upload your first invoice ⬆️</p></td>
                            </tr>
                        </tbody>
                    </Table>
                    <Container hidden={!dataList.length}>
                        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                    </Container>
                    </Col>
                </Row>
            </Container>
            <Container hidden={hideDataInsights} className='data-insights-container'>
                <div>
                    <b>Total: </b>£{grandTotal}<br/>
                    <DropdownButton as={ButtonGroup} variant={'light'} title={dataSetSelectionName} className={'mt-3'}>
                        <Dropdown.Item onClick={() => {setDatasetSpentPerInvoice()}} key={'spent-per-invoice'}>Invoice totals</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setDatasetTotalSpent()}} key={'cumulative'}>Cumulative</Dropdown.Item>
                        {activeDepartment==='Costs' ? <Dropdown.Item onClick={() => {setGraphToSupplierPie()}} key={'spent-per-supplier'}>Per supplier</Dropdown.Item> : <Dropdown.Item onClick={() => {setGraphToCustomerPie()}} key={'spent-per-customer'}>Per customer</Dropdown.Item>}
                        <Dropdown.Item onClick={() => {setGraphToCategoryPie()}} key={'spent-per-category'}>Per category</Dropdown.Item>
                    </DropdownButton>
                    <ButtonGroup aria-label="Basic example" className="float-end mx-1" hidden={hidePieBarSwitch}>
                        <Button variant="light" onClick={showBarChart} disabled={barSelectorDisabled}>Bar</Button>
                        <Button variant="light" onClick={showPieChart} disabled={pieSelectorDisabled}>Pie</Button>
                    </ButtonGroup>
                </div>
                <div className='my-3' style={{position: 'relative', height:'80vh', width:'80vw', margin: 'auto'}}>
                    <Scatter options={options} data={lineData} hidden={!showLine}/>
                    <Pie data={pieData} hidden={!showPie}/>
                    <Bar options={{plugins: {legend: {display: false}}}} data={pieData} hidden={!showBar}/>
                </div>
            </Container>
        </>
    );
}
